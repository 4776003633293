import React, { useState, useEffect } from 'react';

const SearchInput = ({ onSearchSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchTerm) {
      // 模拟 API 调用
      const results = [
        // 模拟的搜索结果
        { id: 1, element: 'Element 1', super: 'super 1' },
        { id: 2, element: 'Element 2', super: 'super 2' },
        { id: 3, element: 'Element 3', super: 'super 3' },
      ];
      setSearchResults(results);
      setShowResults(true);
    } else {
      setShowResults(false);
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSelect = (result, event) => {
    onSearchSelect(result); // 将选中的结果传递给父组件的处理函数
    setSearchTerm(result.element); // 更新输入框为选中结果的名称
    setShowResults(false); // 隐藏搜索结果
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
        placeholder="Search..."
      />
      {showResults && (
        <div className="absolute z-10 w-full rounded-lg bg-gray-700 text-white shadow-md max-h-60 overflow-auto">
          {searchResults.map((result) => (
            <div
              key={result.id}
              onClick={() => handleSelect(result)}
              className="p-2 hover:bg-gray-500 cursor-pointer"
            >
              {result.super + ': ' + result.element}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


export default SearchInput;