import React from 'react';
import { Link } from 'react-router-dom';
import Diversified from '../components/Diversified';
import DiversifiedSubwayLine from '../components/DiversifiedSubwayLine';
import { BlockchainProvider } from '../contexts/DiversifiedContext';

const handleLogout = () => {
  localStorage.removeItem('isLoggedIn');
  window.location.href = '/login';
};

function LedgersPage() {
  return (
    <BlockchainProvider>
    <div className="min-h-screen bg-custom-purple p-10 font-poppins">
      <header className="text-white bg-[#1B1929] p-4 flex justify-between items-center w-full fixed top-0 left-0 right-0 z-50 backdrop-filter backdrop-blur-lg bg-opacity-50">
        <h1 className="text-lg ml-4">Onto Consensus Demo</h1>
        <nav className="mr-4 flex space-x-4">
            <Link to="/bitcoin" className="text-gray-400 hover:text-gray-300">Bitcoin</Link>
            <Link to="/block" className="text-white hover:text-gray-300">Block</Link>
            <Link to="/blockchain" className="text-white hover:text-gray-300">Blockchain</Link>
            <Link to="/distributed" className="text-white hover:text-gray-300">Distributed</Link>
            <Link to="/diversified" className="text-white hover:text-gray-300">Diversified</Link>
            <Link to="/decomposed" className="text-white hover:text-gray-300">Decomposed</Link>
            <Link to="/ledgers" className="text-blue-200 hover:text-gray-300">Blocknet</Link>
            <Link to="/more" className="text-white hover:text-gray-300">More...</Link>
            <button onClick={handleLogout} className="text-white bg-blue-900 text-white w-24 rounded-lg shadow active:bg-blue-700 active:scale-95 transform transition duration-300 cursor-pointer">
                Logout
            </button>
        </nav>

      </header>
      <div className="pt-[4rem]"> {/* 根据头部的实际高度调整上边距 */}
        <div className="fixed top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 my-4 bg-white/50 rounded-lg backdrop-blur-md max-w-xl z-10"
          style={{ backdropFilter: 'blur(30px)', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
          <DiversifiedSubwayLine n={1}/>
          <DiversifiedSubwayLine n={2}/>
          <DiversifiedSubwayLine n={3}/>
        </div>
        <div className="pt-[30vh]"> {/* 这里的 padding-top 是为了给固定位置的容器预留足够的空间 */}
          <Diversified />
          <Diversified />
          <Diversified />
        </div>
      </div>
    </div>
    </BlockchainProvider>
  );
}

export default LedgersPage;
