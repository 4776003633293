import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Blockchain from '../components/BitcoinBlockchain';
import { BlockchainProvider } from '../contexts/BitcoinContext';

function BlockchainPage() {
  const [amount, setAmount] = useState(10); // 默认值10

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    window.location.href = '/login';
  };

  return (
    <BlockchainProvider>
    <div className="min-h-screen bg-custom-purple p-10 font-poppins">
      <header className="text-white bg-[#1B1929] p-4 flex justify-between items-center w-full fixed top-0 left-0 right-0 z-50 backdrop-filter backdrop-blur-lg bg-opacity-90">
        <h1 className="text-lg ml-4">Onto Consensus Demo</h1>
        <nav className="mr-4 flex space-x-4">
            <Link to="/bitcoin" className="text-blue-200 hover:text-gray-300">Bitcoin</Link>
            <Link to="/block" className="text-white hover:text-gray-300">Block</Link>
            <Link to="/blockchain" className="text-white hover:text-gray-300">Blockchain</Link>
            <Link to="/distributed" className="text-white hover:text-gray-300">Distributed</Link>
            <Link to="/diversified" className="text-white hover:text-gray-300">Diversified</Link>
            <Link to="/decomposed" className="text-white hover:text-gray-300">Decomposed</Link>
            <Link to="/ledgers" className="text-white hover:text-gray-300">Blocknet</Link>
            <Link to="/more" className="text-white hover:text-gray-300">More...</Link>
            <button onClick={handleLogout} className="text-white bg-blue-900 text-white w-24 rounded-lg shadow active:bg-blue-700 active:scale-95 transform transition duration-300 cursor-pointer">
                Logout
            </button>
        </nav>

      </header>
      <div className="pt-[4rem]"> {/* 根据头部的实际高度调整上边距 */}
        <Blockchain amount={amount}/>
      </div>
    </div>
    </BlockchainProvider>
  );
}

export default BlockchainPage;