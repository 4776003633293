import React, { useState } from 'react';
import SubwayLine from './SubwayLine';
import { transformData } from '../utils/utils'
import { useBlockchain } from '../contexts/DiversifiedContext';


const DiversifiedSubwayLine = ({ n }) => {
  console.log(n);
  const { blocks, updateBlock } = useBlockchain();
  const transformedObject1 = transformData(blocks[0]);
  const transformedObject2 = transformData(blocks[1]);
  const transformedObject3 = transformData(blocks[2]);
  return (
    <>
      <div className="mb-4">
        {<SubwayLine amount={transformedObject1.amount} node={n} stations={transformedObject1.station} activeStations={transformedObject1.activeStations} />}
      </div>
      <div className="mb-4">
        {<SubwayLine amount={transformedObject2.amount} node={n} stations={transformedObject2.station} activeStations={transformedObject2.activeStations} />}
      </div>
      <div className="mb-4">
        {<SubwayLine amount={transformedObject3.amount} node={n} stations={transformedObject3.station} activeStations={transformedObject3.activeStations} />}
      </div>
    </>
  );
};

export default DiversifiedSubwayLine;

