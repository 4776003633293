// src/contexts/BlockchainContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getStartHash } from '../utils/utils'

const amount = 10;

const BlockchainContext = createContext();

export const useBlockchain = () => useContext(BlockchainContext);

export const BlockchainProvider = ({ children }) => {
    const [blocks, setBlocks] = useState([
        { id: 1, amount: amount, from: '', to: '', zero: getStartHash(amount), hash: '', nonce: '', logs: '', color: 'bg-green-200' },
        { id: 2, amount: amount, from: '', to: '', zero: getStartHash(amount), hash: '', nonce: '', logs: '', color: 'bg-green-200' },
        { id: 3, amount: amount, from: '', to: '', zero: getStartHash(amount), hash: '', nonce: '', logs: '', color: 'bg-green-200' },
        { id: 4, amount: amount, from: '', to: '', zero: getStartHash(amount), hash: '', nonce: '', logs: '', color: 'bg-green-200' },
        { id: 5, amount: amount, from: '', to: '', zero: getStartHash(amount), hash: '', nonce: '', logs: '', color: 'bg-green-200' },
      ]);

    const updateBlock = (blockId, updatedData) => {
        console.log(updatedData);
        const updatedBlocks = blocks.map(block => {
            // 如果是指定的blockId，更新其全部信息
            if (block.id === blockId && updatedData.color==='bg-green-200' && updatedData.hash) {
                return { ...block, color: updatedData.color, hash: updatedData.hash };
            }
            else if (block.id > blockId && updatedData.color==='bg-green-200' && updatedData.hash && block.hash==='') {
                return { ...block, color: updatedData.color, zero: updatedData.hash };
            }
            else if (block.id >= blockId && updatedData.color) {
                return { ...block, color: updatedData.color };
            }
            return block;
        });
        console.log(updatedBlocks); 
        setBlocks([...updatedBlocks]);
    };
    useEffect(() => {
        console.log('Blocks have been updated:', blocks);
      }, [blocks]);

    return (
        <BlockchainContext.Provider value={{ blocks, updateBlock }}>
            {children}
        </BlockchainContext.Provider>
    );
};
