import React, { createContext, useContext, useState, useEffect } from 'react';
import { getStartHash } from '../utils/utils'

const amount = 1268.64;

const BlockchainContext = createContext();

export const useBlockchain = () => useContext(BlockchainContext);

export const BlockchainProvider = ({ children }) => {
    const [blocks, setBlocks] = useState([{ id: 0, amount: 1268.64, from: 'Mike', to: 'William', zero: 0, hash: '', nonce: '', logs: '', color: 'bg-green-200' }]);
    const [timestamps, setTimestamps] = useState([1712064078850, 1712064080047, 1712064080346, 1712064081029, 1712064082333, 1712064083137, 1712064083719])
    
    // 检查所有hash是否以'0000'开头
    const allStartsWithZero = blocks.length > 1 ? 
    blocks.slice(1).map(block => block.hash).every(hash => hash.startsWith('0000')) : false;

    const updateBlock = (blockId, updatedData) => {
        console.log(updatedData);
        if (blockId == 0 && updatedData.decomposed) {
            setBlocks([blocks[0], ...updatedData.decomposed.map((number,index) => (
                { id: index+1, amount: number, from: 'Mike', to: 'William', zero: getStartHash(number), timestamp: timestamps[index+1], hash: '', nonce: '', logs: '', color: 'bg-green-200' }
            ))])
        }
        else {
        const updatedBlocks = blocks.map(block => {
            // 如果是指定的blockId，更新其全部信息
            if (block.id === blockId && updatedData.color==='bg-green-200' && updatedData.hash) {
                return { ...block, color: updatedData.color, hash: updatedData.hash };
            }
            else if (blockId===0 && block.id >= blockId && updatedData.color) {
                return { ...block, color: updatedData.color };
            }
            // 自己
            else if (blockId > 0 && block.id == blockId && updatedData.color) {
                return { ...block, color: updatedData.color };
            }
            // 下犯上
            else if (blockId > 0 && block.id == 0 && updatedData.color) {
                return { ...block, color: updatedData.color };
            }
            return block;
        });
        console.log(updatedBlocks); 
        setBlocks([...updatedBlocks]);
    }
    };
    useEffect(() => {
        console.log(blocks.slice(1).map(block => block.hash));
        console.log('Blocks have been updated:', blocks);
      }, [blocks]);

    return (
        <BlockchainContext.Provider value={{ blocks, updateBlock, allStartsWithZero }}>
            {children}
        </BlockchainContext.Provider>
    );
};
