import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); 
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [message, setMessage] = useState(null); // 用于存储和展示消息
  const [messageType, setMessageType] = useState(''); // 消息类型: 'success' 或 'error'
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
        let data = {success:1};
        if ((username === "ranliu" || username === "ran") && password === "ran123") {
        // const response = await fetch('https://fastapi_deta-1-a0156659.deta.app/login/', { // 后端登录接口
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ username: username, password: password }), // 发送用户名和密码
        // });

        // const data = await response.json(); // 解析JSON响应

        // if (data.username) {
            setMessage("Login successful!");
            setMessageType('success');
            localStorage.setItem('isLoggedIn', true); // 标记用户已登录
            setTimeout(() => { navigate('/'); }, 500); // 等待0.5秒后跳转 // 导航到首页或其他页面
        } else {
            setMessage(data.message || "Login failed: Invalid username or password");
            setMessageType('error');
        }
    } catch (error) {
        setMessage("An error occurred during login.");
        setMessageType('error');
    }
    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[rgb(50,46,63)] text-white">
    {/* Logo 图片区域 */}
      <div className="w-1/3 flex justify-center items-center">
        <img src="/logo.PNG" alt="logo" className="max-w-full h-auto" />
      </div>
      {/* <p className="text-4xl text-center text-white font-mono"
            style={{
            fontFamily: '"IBM Plex Mono", monospace'
          }}>
          Consensus Demo
      </p> */}
      <div className={`p-6 m-4 shadow-lg rounded-xl bg-blue-200 transform transition duration-100 hover:scale-105 font-mono backdrop-filter backdrop-blur-lg bg-opacity-20`}>
        <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
                <label className="font-semibold text-white w-32">Username:</label>
                <input
                    type="text"
                    className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => {setUsername(e.target.value);}}
                    required
                />
            </div>
            <div className="flex items-center space-x-2">
                <label className="font-semibold text-white w-32">Password:</label>
                <input
                    type="password"
                    className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {setPassword(e.target.value);}}
                    required
                />
            </div>
            {/* 消息展示 */}
            {message && (
                <div className={`message ${messageType}`}>
                    {message}
                </div>
            )}
            <div className='flex justify-center '>
                <button className={`btn ${isSubmitting ? 'bg-gray-500 scale-95' : 'bg-blue-900'} text-white w-24 rounded-lg shadow mt-4 active:bg-blue-700 active:scale-95 transform transition duration-300`} onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting ? <i className="fas fa-spinner animate-spin"></i> : 'Login'}
                </button>
            </div>
        </div>
      </div>
      <p className="text-xs text-center text-gray-400">
          ©2024 Consensus Demo. All rights reserved.
      </p>
    </div>
  );
};

export default Login;
