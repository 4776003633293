import React, { useState, useEffect } from 'react';
import sha256 from 'crypto-js/sha256';
import { generateLogs, getStartHash, mine, sleep, calculateExpression, padRight  } from '../utils/utils'
import { useBlockchain } from '../contexts/DecomposedContext';

const BlockA = ({ blockData }) => {
  const { blocks, updateBlock, allStartsWithZero } = useBlockchain();
  const [amount, setAmount] = useState(blockData.amount);
  const [from, setFrom] = useState(blockData.from);
  const [to, setTo] = useState(blockData.to);
  const [timestamp, setTimestamp] = useState(blockData.timestamp);
  const [zero, setZero] = useState(getStartHash(amount))
  const [nonce, setNonce] = useState(blockData.nonce);
  const [hash, setHash] = useState(blockData.hash);
  const [logs, setLogs] = useState(blockData.logs);
  const [statusColor, setStatusColor] = useState(blockData.color);
  const [isCalculating, setIsCalculating] = useState(false); // 新增状态来跟踪计算状态
  const [userModified, setUserModified] = useState(false);

  // 当组件挂载时，用上下文中的数据初始化内部状态
  useEffect(() => {
    const thisBlockData = blocks.find(block => block.id === blockData.id);
    const newBlockHash = blocks.find(block => block.hash.slice(0,4) === '0000');
    if (newBlockHash && !logs.includes('|')) {
      let logsStr = logs;
      // logsStr+='\n'+padRight(newBlockHash.amount.toString(), 10)+'\t| '+newBlockHash.hash;
      logsStr+='\n'+padRight('1000', 10)+'\t| '+'000085c99241b95e85058f37bf6dcf8f30bda19c5df86ffc8021790a3f7cc8dd';
      logsStr+='\n'+padRight('200', 10)+'\t| '+'0000157c9b13af35158d15a467ff546785ed5f214a92078e85f5ecef210a52f4';
      logsStr+='\n'+padRight('60', 10)+'\t| '+'0000e65e12085cc7e3eb2873b952ab6c57151a6f8ae0a8cd34f1f7064790f878';
      logsStr+='\n'+padRight('8', 10)+'\t| '+'0000973d227b1850a9ede0fa4108b886d910ef57aefe1dbc60ed764757ec9e20';
      logsStr+='\n'+padRight('0.6', 10)+'\t| '+'0000d3b61353cd74d542bfe6569311e26a7b6a67a348798b92704d9488e6a5ed';
      logsStr+='\n'+padRight('0.04', 10)+'\t| '+'0000f048b85de7fff014804a388f8b6ab3407019361a2380968eaca3459f87b7';
      setLogs(logsStr);
      let [nonce_, hash_] = mine([amount, from, to, timestamp, logs], "0000")
      setHash(hash_);
      setNonce(nonce_);
      setStatusColor('bg-green-200');
    }
    if (thisBlockData) {
      setStatusColor(thisBlockData.color);
    }
  }, [blocks]); // 依赖项数组中的 blocks 确保了当 blocks 更新时，这个效果能重新运行

  useEffect(() => {
    if (allStartsWithZero) {
      let [nonce_, hash_] = mine([amount, from, to, timestamp, logs], "0000")
      setHash(hash_);
      setNonce(nonce_);
      setStatusColor('bg-green-200');
    }
  }, [allStartsWithZero]); // 依赖项是所有B组件hash是否符合条件

  const handleNavigate = () => {
    setIsCalculating(true);
    setUserModified(false); // 设置为false因为这是程序引起的变化
    setTimestamp('1712064078850');
    updateBlock(0, {decomposed: calculateExpression(amount).decomposed});
    console.log(calculateExpression(amount).decomposed);
    setLogs(amount + ' = ' + calculateExpression(amount).express);
    setIsCalculating(false);
  }

  // 当任何关键字段变化时，设置为已修改状态（红色背景）
  useEffect(() => {
    if (userModified) {
      const warning = 'bg-red-400/50'//'bg-gradient-to-r from-red-900 to-red-300 border-red-500 border-4'
      setStatusColor(warning);
      const inputStr = `${[amount, from, to, timestamp, logs].join('')}`;
      setHash(sha256(inputStr).toString());
      updateBlock(blockData.id, {color: warning});
    }
  }, [amount, from, to, timestamp, logs, nonce]);

  return (
    <div className={`p-6 m-4 shadow-lg rounded-xl ${statusColor} transform transition duration-100 hover:scale-105 font-poppins backdrop-filter backdrop-blur-lg bg-opacity-20`}>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Amount:</label>
            <input
                type="number"
                className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                placeholder="Amount (0 - 10,000)"
                min="0"
                max="10000"
                step="0.01"
                value={amount}
                // onChange={(e) => {setAmount(e.target.value);setUserModified(true);}}
                readOnly
            />
        </div>
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">From:</label>
            <input
                type="text"
                className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                placeholder="From"
                value={from}
                onChange={(e) => {setFrom(e.target.value);setUserModified(true);}}
            />
        </div>
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">To:</label>
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
            placeholder="To"
            value={to}
            onChange={(e) => {setTo(e.target.value);setUserModified(true);}}
            />
        </div>

        <div className='flex justify-center '>
          <button className={`btn ${isCalculating ? 'bg-gray-500 scale-95' : 'bg-blue-900'} text-white w-32 rounded-lg shadow mt-4 active:bg-blue-700 active:scale-95 transform transition duration-300`} onClick={handleNavigate} disabled={isCalculating}>
            {isCalculating ? <i className="fas fa-spinner animate-spin"></i> : 'Decomposed'}
          </button>
        </div>

        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Timestamp:</label>
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500 font-mono"
            placeholder={""}
            value={timestamp}
            onChange={(e) => {setTimestamp(e.target.value);setUserModified(true);}}
            />
        </div>

        <div className="flex items-center space-x-2 text-xs font-mono">
            <label className="font-semibold text-white w-32">Zero:</label>
                <input
                type="text"
                className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500 font-mono"
                placeholder="Zero Hash"
                value={zero}
                readOnly
                // onChange={(e) => {setStatusColor('bg-red-200');setUserModified(true);}}
                />
        </div>


        {/* 根据hasNavigated状态条件性渲染下方元素 */}
        <textarea
          className="textarea textarea-bordered w-full max-w-lg h-44 bg-gray-700 text-white rounded-lg text-xs font-mono"
          style={{
            fontFamily: '"IBM Plex Mono", monospace',
            // overflowX: 'auto', /* 允许水平滚动 */
            whiteSpace: 'pre', /* 防止自动换行 */
          }}
          placeholder="Path Broadcast"
          value={logs}
          onChange={(e) => {setLogs(e.target.value); setUserModified(true);}}
        />

        {/* Nonce 和 Hash 输入框 */}

        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Nonce:</label>
            <input
              type="number"
              className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500 font-mono"
              placeholder="Nonce"
              value={nonce}
              onChange={(e) => {setNonce(e.target.value);setUserModified(true);}}
              // readOnly
            />
        </div>
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Hash:</label>
            {isCalculating ? <i className="fas fa-spinner animate-spin"></i> : 
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500 font-mono"
            placeholder="Hash"
            value={hash}
            // onChange={(e) => {setHash(e.target.value);setUserModified(true);updateBlockHash(e.target.value);}}
            // readOnly
            />
          }
        </div>

      </div>
    </div>
  );
};

export default BlockA;

