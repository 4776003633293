import sha256 from 'crypto-js/sha256';

const mine = (inputs, targetPrefix) => {
    let nonce = 0;
    let hash = "";
    do {
      nonce += 1;
      const inputStr = `${inputs.join('')}${nonce}`;
      hash = sha256(inputStr).toString();
    } while (!hash.startsWith(targetPrefix));
    return [nonce, hash]

  }

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

function padRight(str, length) {
    if (str.length >= length) return str;
    return str + " ".repeat(length - str.length);
  }

const generateLogs = (amount) => {
    // 模拟生成日志信息
    let logs = '';
    let logsV = [];
    let numbers = generateFixedNumbers(amount);
    let steps = generateFixedNumbersInStep(amount);
    let times = generateSortedRandomTime();
    for (let i = 1; i <= 5; i++) {
      let numberStr = padRight(numbers[i - 1].toString(), 10); // Number字段保证至少10个字符宽度
      let stepsStr = padRight(steps[i - 1].toString(), 5); // Step字段保证至少5个字符宽度
      let timeStr = padRight((1000 * times[i - 1]).toString(), 6); // Time字段保证至少6个字符宽度
      let hashStr = getStartHash(numbers[i - 1]); // hash函数返回的值
      let secondHashStr = sha256(hashStr+numbers[i - 1]);
      let thisStr = '';

      if (amount === 709.56 && i === 3) {
        thisStr = `${padRight('10', 10)}| D = ${padRight('352', 5)}| T = ${padRight(times[i-1].toString(), 6)}| ${hashStr}`;
      }
      else if (amount === 4800.68 && i === 4) {
        thisStr = `${padRight('10', 10)}| D = ${padRight('719', 5)}| T = ${padRight(times[i-1].toString(), 6)}| ${hashStr}`;
      }
      else {
        thisStr = `${numberStr}| D = ${stepsStr}| T = ${timeStr}| ${hashStr}`;
      }
      logs += "\n" + thisStr;
      let emptySpaceLength = 10 + 5 + 6 + 2*3 + 4*2; // 使用具体空格数来模拟之前字段的总长度
      // 构建并添加第二行，只有第四列的值
      logs += "\n" + " ".repeat(emptySpaceLength) + secondHashStr;
      logsV.push("\n" + thisStr.replace(/(\d+)\s/g, '$1v') + "v"+"\n" + " ".repeat(emptySpaceLength) + secondHashStr + "v")
      logs += i > 2 ? logsV[i-3] : '';
    }
    logs += logsV[3] + logsV[4] + logsV[5];
    return logs;
  };

const getStartHash = (num) => {
    let nonce_ = 0;
    let hash_ = "";
    const targetPrefix = "0000";
  
    do {
      nonce_ += 1;
      hash_ = sha256(`${num}${nonce_}`).toString();
    } while (!hash_.startsWith(targetPrefix));
    return hash_
  };


const generateSortedRandomTime = () => {
    const numbers = [];
    for (let i = 0; i < 5; i++) {
      // 生成0.6到1.5之间的数，间隔为0.01
      // Math.random() 生成 [0, 1) 之间的随机数
      // 乘以 (1.5 - 0.6) / 0.01 得到 [0, 90) 之间的数
      // 加上 60 转换为 [60, 150) 之间的数
      // 最后除以 100 转换为 [0.6, 1.5) 之间的数
      const number = Math.floor(Math.random() * (90) + 60) / 100;
      numbers.push(number);//毫秒
    }
    
    // 对生成的数字进行排序
    numbers.sort((a, b) => a - b);
    
    return numbers;
}


const generateFixedNumbersInStep = (seedNumber) => {
    // 将输入数字转换为字符串形式作为种子
    const seedStr = seedNumber.toString();
    
    // 初始化伪随机数生成器的种子
    let pseudoRandomSeed = 0;
    for (let i = 0; i < seedStr.length; i++) {
      pseudoRandomSeed = (pseudoRandomSeed * 10 + seedStr.charCodeAt(i)) % 10000;
    }
    
    // 生成伪随机数的函数
    function pseudoRandom() {
      const x = Math.sin(pseudoRandomSeed++) * 10000;
      return x - Math.floor(x);
    }
    
    // 生成并返回五个固定的、从小到大排列的数
    const fixedNumbers = [];
    for (let i = 0; i < 5; i++) {
      // 生成100-1000之间的数，间隔为1
      const number = Math.floor(pseudoRandom() * 901) + 100; // 生成[0, 900]的随机数再加上100
      fixedNumbers.push(number);
    }
    // 对生成的数字进行排序
    fixedNumbers.sort((a, b) => a - b);
    
    return fixedNumbers;
}


const generateFixedNumbers = (seedNumber) => {
    // 将输入数字转换为字符串形式作为种子
    const seed = seedNumber.toString();
    
    // 初始化伪随机数生成器的种子
    let pseudoRandomSeed = 0;
    for (let i = 0; i < seed.length; i++) {
      pseudoRandomSeed = (pseudoRandomSeed * 10 + seed.charCodeAt(i)) % 10000;
    }
    
    // 生成伪随机数的函数
    const pseudoRandom = () => {
      const x = Math.sin(pseudoRandomSeed++) * 10000;
      return x - Math.floor(x);
    }
    
    // 生成并返回五个固定的、不包含0的数
    const fixedNumbers = [];
    while (fixedNumbers.length < 5) {
      // 生成0-10000之间的数，间隔0.01
      const number = Math.floor(pseudoRandom() * 1000000) / 100;
      // 确保生成的数不是0
      if (number !== 0) {
        fixedNumbers.push(number);
      }
    }
    
    return fixedNumbers;
}

const stringToFixedNumber = (str) => {
  let baseNumber = 0;
  for (let i = 0; i < str.length; i++) {
      baseNumber += str.charCodeAt(i);
  }
  const pseudoRandom = (baseNumber * 104729) % 1000000; // 104729 is a prime number
  return pseudoRandom / 100; // Normalize to range 0-10000 with step 0.01
}


function calculateExpression(number) {
  // Convert the number to a string to easily access each digit
  let [integerPart, decimalPart] = number.toString().split('.');
  
  // Pad the decimal part with zeros to ensure it has at least 2 digits
  decimalPart = decimalPart ? decimalPart.padEnd(2, '0') : '00';

  let decomposed = [];
  
  // Decompose the integer part
  for (let i = 0; i < integerPart.length; i++) {
    let digit = integerPart[i];
    if (digit !== '0') {
      let zeros = '0'.repeat(integerPart.length - i - 1);
      decomposed.push(digit + zeros);
    }
  }
  
  // Decompose the decimal part
  if (decimalPart[0] !== '0') {
    decomposed.push('0.' + decimalPart[0]);
  }
  if (decimalPart[1] !== '0') {
    decomposed.push('0.0' + decimalPart[1]);
  }
  
  return {express: decomposed.join(' + '), decomposed: decomposed};
}

//   return {express: result.join('+'), usedArr: usedArr};
// }
// 帮我选定一个1000以内的常用数字的数组，比如0.01，0.05, 0.9, 0.1，0.5，0.99, 1，2，3，4，5，6，7，8，9...19,20,30,40,..90,100,150,200,..900,1000,起名叫做commonNumbers。用js实现一个函数：输入一个数字，就可以采用commonNumbers中的尽可能少的数字，用加减乘除进行计算，然后结果正是输入的这个数字。函数会返回用到的数字和计算方法
// 我重新梳理了一下思路，请帮我实现：对于const commonNumbers = [0.01, 0.05, 0.1, 0.5, 0.99, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000]这个给定的数组，输入一个10000以内的数字，拆分成用加减乘除的表达式，其结果是输入值。寻找的过程不需要用很长的迭代。比如6456.76这个例子，先是6*1000，4*100，然后是5*10，但个位数这里遇到了问题，6在前面被用过了，那就用少量的迭代，使用1-20之间的任意两个数字，迭代得出可以用19-13，所以个位是19-13。十分位是7*0.1，百分位的6又是被用过的，所以通过迭代发现用（8-2）*0.02。最终的表达式为：6*1000+4*100+5*10+19-13+7*0.1*8-2）*0.02

const transformData = (blocks) => {
  // 第一个元素：amount 的值
  const amount = blocks[1].amount;

  // 第二个元素：原数据的序号数组
  const station = blocks.map((_, index) => index);

  // 第三个元素：color 不是 'bg-green-200' 的序号数组
  const activeStations = blocks.reduce((acc, block, index) => {
    if (block.color !== 'bg-green-200') {
      acc.push(index);
    }
    return acc;
  }, []);

  return {amount, station, activeStations};
};

export { generateLogs, getStartHash, sleep, mine, stringToFixedNumber, calculateExpression, padRight, transformData };