import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import BlockchainPage from './pages/BlockchainPage'; 
import BitcoinPage from './pages/BitcoinPage'; 
import BlockPage from './pages/BlockPage'; 
import DiversifiedPage from './pages/DiversifiedPage'; 
import DistributedPage from './pages/DistributedPage'; 
import DecomposedPage from './pages/DecomposedPage'; 
import LedgersPage from './pages/LedgersPage'; 
import MorePage from './pages/MorePage'; 
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <div>
        {/* 导航链接 */}
        {/* <nav>
          <Link to="/login">Login</Link> | 
          <Link to="/block">Block</Link> | 
          <Link to="/blockchain">Blockchain</Link> | 
          <Link to="/distributed">Distributed</Link>
          <Link to="/diversified">DistributedPage</Link> |
          <Link to="/ledgers">R-edgers</Link> |
          <Link to="/more">More...</Link> 
        </nav> */}
        {/* 路由配置 */}
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* 可以添加一个重定向或默认路由 */}
          <Route path="/" element={<ProtectedRoute><BlockPage /></ProtectedRoute>} />
          <Route path="/bitcoin" element={<ProtectedRoute><BitcoinPage /></ProtectedRoute>} />
          <Route path="/block" element={<ProtectedRoute><BlockPage /></ProtectedRoute>} />
          <Route path="/blockchain" element={<ProtectedRoute><BlockchainPage /></ProtectedRoute>} />
          <Route path="/diversified" element={<ProtectedRoute><DiversifiedPage /></ProtectedRoute>} />
          <Route path="/distributed" element={<ProtectedRoute><DistributedPage /></ProtectedRoute>} />
          <Route path="/decomposed" element={<ProtectedRoute><DecomposedPage /></ProtectedRoute>} />
          <Route path="/ledgers" element={<ProtectedRoute><LedgersPage /></ProtectedRoute>} />
          <Route path="/more" element={<ProtectedRoute><MorePage /></ProtectedRoute>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

