import React, { useState } from 'react';
import Block from './DiversifiedBlock';
import { useBlockchain, BlockchainProvider } from '../contexts/DiversifiedContext';

const Diversified = () => {
  const { blocks, updateBlock } = useBlockchain();
  // console.log(blocks[0].map((block) => (block.id)));

  return (
    <>
      <div className="mt-16 flex overflow-x-auto">
        {blocks[0].map((block) => (
            <Block
            key={block.id}
            blockData={block}
          />
          ))
        }
      </div>
      <div className="mt-16 flex overflow-x-auto">
        {blocks[1].map((block) => (
            <Block
            key={block.id}
            blockData={block}
          />
          ))
        }
      </div>
      <div className="mt-16 flex overflow-x-auto">
        {blocks[2].map((block) => (
            <Block
            key={block.id}
            blockData={block}
          />
          ))
        }
      </div>
    </>
  );
};

export default Diversified;

