import React, { useState } from 'react';
import BlockA from '../components/DecomposedBlockA';
import BlockB from '../components/DecomposedBlockB';
import { useBlockchain, BlockchainProvider } from '../contexts/DecomposedContext';

const Decomposed = () => {
    const { blocks, updateBlock } = useBlockchain();

  return (
    <>
      <div className="mt-16 flex overflow-x-auto justify-center">
        <BlockA blockData={blocks[0]}/>
      </div>
      
        <div className="mt-16 flex overflow-x-auto">
          {blocks.slice(1).map(block => (<BlockB blockData={block}/>))}
        </div>
    </>
  );
};

export default Decomposed;

