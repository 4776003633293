import React, { useState } from 'react';
import SubwayLine from './SubwayLine';
import { transformData } from '../utils/utils'
import { useBlockchain } from '../contexts/DistributedContext';


const DistributedSubwayLine = ({ n }) => {
  const { blocks, updateBlock } = useBlockchain();
  const transformedObject = transformData(blocks);
  return (
    <>
      <div className="mb-4">
        {<SubwayLine amount={transformedObject.amount} node={n} stations={transformedObject.station} activeStations={transformedObject.activeStations} />}
      </div>
    </>
  );
};

export default DistributedSubwayLine;

