import React, { useState, useEffect } from 'react';
import sha256 from 'crypto-js/sha256';
import { generateLogs, getStartHash, mine, sleep, stringToFixedNumber } from '../utils/utils'
import SearchInput from './SearchInput';

const Block = ({ blockData, prevHash, updateBlock }) => {
  const [path, setPath] = useState('');
  const [amount, setAmount] = useState('');
  const [timestamp, setTimestamp] = useState(blockData.timestamp);
  const [zero, setZero] = useState('')
  const [nonce, setNonce] = useState(blockData.nonce);
  const [hash, setHash] = useState(blockData.hash);
  // const [logs1, setLogs1] = useState('()+()->()->()');
  const [logs, setLogs] = useState(blockData.logs);
  const [statusColor, setStatusColor] = useState('bg-green-200');
  const [isCalculating, setIsCalculating] = useState(false); // 新增状态来跟踪计算状态
  const [userModified, setUserModified] = useState(false);

  const [selectedResult, setSelectedResult] = useState(null);

  const handleSearchSelect = (result) => {
      console.log("Selected search result:", result);
      setSelectedResult(result);
      setStatusColor('bg-red-400/50');
  };

  const handleNavigate = () => {
    handleSearchSelect(selectedResult);
    setUserModified(false); // 设置为false因为这是程序引起的变化
    setStatusColor('bg-green-200');
    setTimestamp(Date.now());
    setAmount(stringToFixedNumber(selectedResult.element+selectedResult.super));
    setPath(sha256(selectedResult.element+selectedResult.super));
    setZero(getStartHash(amount));
    async function processLogs(logs) {
      let logsStr = '';
      for (let log of logs) {
        logsStr += log + '\n';
        setLogs(logsStr.replace(/^\n/, ''));
        await sleep(500);
      }
      let [nonce_, hash_] = mine([path, amount, timestamp, logs], "0000")
      setHash(hash_);
      setNonce(nonce_);
    }
    processLogs(generateLogs(amount).split('\n'))
  
    // updateBlockHash(hash_);
    // console.log(`Nonce: ${nonce_}`);
    // console.log(`Hash: ${hash_}`);
  }

  // 当任何关键字段变化时，设置为已修改状态（红色背景）
  useEffect(() => {
    // updateBlock({amount: amount, from: from, to: to, timestamp:timestamp, hash:hash, logs:logs})
    if (userModified) {
      setStatusColor('bg-red-400/50');
      const inputStr = `${[amount, timestamp, logs, nonce].join('')}`;
      setHash(sha256(inputStr).toString());
    }
  }, [selectedResult,path, amount, timestamp, logs, nonce]);
  
  
  useEffect(() => {
    if (hash.slice(4) === '0000') {
      updateBlock(blockData.id, {hash: hash})
    }
  }, [hash]);

  return (
    <div className={`p-6 m-4 shadow-lg rounded-xl ${statusColor} transform transition duration-100 hover:scale-105 font-poppins backdrop-filter backdrop-blur-lg bg-opacity-20`}>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Element:</label>
            <SearchInput onSearchSelect={handleSearchSelect} />
        </div>
        <div className='flex justify-center '>
          <button className={`btn ${isCalculating ? 'bg-gray-500 scale-95' : 'bg-blue-900'} text-white w-24 rounded-lg shadow mt-4 active:bg-blue-700 active:scale-95 transform transition duration-300`} onClick={handleNavigate} disabled={isCalculating}>
            {isCalculating ? <i className="fas fa-spinner animate-spin"></i> : '2S Navigate'}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <label className="font-semibold text-white w-32">Path Hash:</label>
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
            placeholder="Path Hash"
            value={path}
            readOnly
            onChange={(e) => {setPath(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
            />
    </div>
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Anchor:</label>
            <input
                type="number"
                className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                placeholder="0 - 10,000"
                min="0"
                max="10000"
                step="0.01"
                value={amount}
                // readOnly
                onChange={(e) => {setAmount(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
            />
        </div>


        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Timestamp:</label>
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
            placeholder={""}
            value={timestamp}
            onChange={(e) => {setTimestamp(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
            />
        </div>

        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Anchor Zero:</label>
                <input
                type="text"
                className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
                placeholder="Zero Hash"
                value={zero}
                onChange={(e) => {setZero(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
                />
        </div>


        {/* 根据hasNavigated状态条件性渲染下方元素 */}
        <textarea
          className="textarea textarea-bordered w-full max-w-lg h-80 bg-gray-700 text-white rounded-lg text-xs font-mono"
          style={{ fontFamily: '"Roboto Mono", monospace' }}
          placeholder="Path Broadcast"
          value={logs}
          onChange={(e) => {setLogs(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
        />

        {/* Nonce 和 Hash 输入框 */}

        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Nonce:</label>
            <input
              type="number"
              className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
              placeholder="Nonce"
              value={nonce}
              onChange={(e) => {setNonce(e.target.value);setStatusColor('bg-red-400/50');setUserModified(true);}}
              // readOnly
            />
        </div>
        <div className="flex items-center space-x-2">
            <label className="font-semibold text-white w-32">Hash:</label>
            {isCalculating ? <i className="fas fa-spinner animate-spin"></i> : 
            <input
            type="text"
            className="input input-bordered w-64 max-w-xs rounded-lg bg-gray-700 text-white focus:ring focus:ring-gray-500"
            placeholder="Hash"
            value={hash}
            // onChange={(e) => {setHash(e.target.value);setUserModified(true);updateBlockHash(e.target.value);}}
            // readOnly
            />
          }
        </div>


      {isCalculating && (<i className="fas fa-spinner animate-spin"></i>)}


      </div>
    </div>
  );
};

export default Block;