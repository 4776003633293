import React from 'react';

const SubwayLine = ({ amount, node, stations, activeStations }) => {
    return (
        <div className="mx-auto flex items-center justify-center max-w-lg">
            <div className={`${activeStations.length > 0 ? 'text-red-500/90' : 'text-green-200/60'} min-w-[7em] text-cente font-mono`} style={{ fontFamily: '"Roboto Mono", monospace' }}>{amount+"_n"+node} </div>
            {stations.map((index) => (
                <React.Fragment key={index}>
                    {index !== 0 && <div className={`w-20 border-t border-2 ${activeStations.includes(index-1) ? 'border-red-500/30' : 'border-gray-500/40'} line-element`}></div>}
                    <div
                        className={`w-2.5 h-2.5 rounded-full ${activeStations.includes(index) ? 'bg-red-500/70' : 'bg-green-200/60'}`} 
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

export default SubwayLine;

