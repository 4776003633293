import React, { useState } from 'react';
import Block from './DistributedBlock';
import { useBlockchain, BlockchainProvider } from '../contexts/DistributedContext';

const Distributed = () => {
  const { blocks, updateBlock } = useBlockchain();
  // console.log(blocks);
  return (
    <>
      {/* 单独展示 amount 的部分 */}
      {/* <div className="text-left mt-2">
      <span className="text-lg font-bold text-white">Amount: {amount}</span>
    </div> */}
      <div className="mt-16 flex overflow-x-auto">
        {blocks.map((block) => 
          <Block
            key={block.id}
            blockData={block}
          />
          )}
      </div>
    </>
  );
};

export default Distributed;

