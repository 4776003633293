import React, { useState } from 'react';
import Block from './BitcoinBlock';
import { useBlockchain, BlockchainProvider } from '../contexts/BitcoinContext';

const Blockchain = ({ amount }) => {
    const { blocks, updateBlock } = useBlockchain();

  return (
    <>
    {/* 单独展示 amount 的部分 */}
    {/* <div className="text-left mt-2">
      <span className="text-lg font-bold text-white">Amount: {amount}</span>
    </div> */}
    <div className="mt-16 flex overflow-x-auto">
      {blocks.map((block) => (
        <Block
            key={block.id}
            blockData={block}
            // updateBlock={updateBlock}
        />
      ))}
    </div>
    </>
  );
};

export default Blockchain;

