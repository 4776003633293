import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Block from '../components/OneBlock';

const handleLogout = () => {
  localStorage.removeItem('isLoggedIn');
  window.location.href = '/login';
};

function BlockPage() {
  const [blocks, setBlocks] = useState([
    { id: 1, amount: 10, from: '', to: '', zero: '', hash: '', nonce: '' },
  ]); // 初始状态为5个空区块

  // 更新特定区块的状态
  const updateBlock = (blockId, updatedData) => {
    setBlocks(blocks.map(block => 
        block.id === blockId ? { ...block, ...updatedData } : 
        block.id === blockId + 1 ? { ...block, zero: updatedData.hash } : block
    ));
  };
  return (
    <div className="min-h-screen bg-custom-purple p-10 font-poppins">
      <header className="text-white bg-[#1B1929] p-4 flex justify-between items-center w-full fixed top-0 left-0 right-0 z-50 backdrop-filter backdrop-blur-lg bg-opacity-50">
        <h1 className="text-lg ml-4">Onto Consensus Demo</h1>
        <nav className="mr-4 flex space-x-4">
            <Link to="/bitcoin" className="text-gray-400 hover:text-gray-300">Bitcoin</Link>
            <Link to="/block" className="text-blue-200 hover:text-gray-300">Block</Link>
            <Link to="/blockchain" className="text-white hover:text-gray-300">Blockchain</Link>
            <Link to="/distributed" className="text-white hover:text-gray-300">Distributed</Link>
            <Link to="/diversified" className="text-white hover:text-gray-300">Diversified</Link>
            <Link to="/decomposed" className="text-white hover:text-gray-300">Decomposed</Link>
            <Link to="/ledgers" className="text-white hover:text-gray-300">Blocknet</Link>
            <Link to="/more" className="text-white hover:text-gray-300">More...</Link>
            <></>
            <button onClick={handleLogout} className="text-white bg-blue-900 text-white w-24 rounded-lg shadow active:bg-blue-700 active:scale-95 transform transition duration-300 cursor-pointer">
                Logout
            </button>
        </nav>

      </header>
      <div className="pt-[4rem] flex justify-center items-center"> {/* 使用flex布局居中Block组件 */}
        <div className="flex justify-center items-center"> {/* 这个div确保Block组件水平和垂直居中 */}
        {blocks.map((block) => (
        <Block
            key={block.id}
            blockData={block}
            prevHash={block.id === 1 ? null : blocks[block.id - 1].hash}
            updateBlock={updateBlock}
        />
      ))}
        </div>
      </div>
    </div>
  );
}

export default BlockPage;